<template>
  <div class="">
    <div class="bg-blueGray-100 pb-20 md:pb-6 onprint">
      <sidebar />
      <div class="relative md:ml-60 bg-white onprint min-h-screen">
        <navbar v-if="$route.path.includes('dashboard')" class="md:hidden" />
        <navbar class="hidden md:block" />
        <!-- <header-stats /> -->
        <div class="md:mx-auto w-full pb-10">
          <router-view />
        </div>
      </div>
    </div>
    <APIDashboardLoader v-if="showLoader" />
  </div>
</template>

<script setup>
import Sidebar from "@/components/Sidebar/ApiSidebar.vue";
import Navbar from "@/components/Navbars/ApiNavbar.vue";
import { useDataStore } from "@/stores/data.js";
import { ref, onMounted, computed } from "vue";

const store = useDataStore();
const { query, mutate } = store;

const showLoader = ref(false);
const companyData = computed(() => store.getCompanyAdmin);
console.log(companyData.value);

onMounted(async () => {
  await initApp();
});

const initApp = async () => {
  try {
    showLoader.value = true;
    await queryContributions();
    await queryProducts();
    await queryApiCalls();
    await queryProfile();
  } catch (error) {
    console.log(error);
  } finally {
    showLoader.value = false;
  }
};

const queryContributions = async () => {
  try {
    const response = await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
    const { company } = response;
    if (!company) return;

    const payload = {
      input: {
        companyId: company._id,
        filterDays: 7,
        from: null,
        productId: null,
        to: null,
      },
    };

    const res = await query({
      endpoint: "GetCompanyContributions",
      payload: payload,
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiContribution: res.data,
      });
    }

    console.log(res.data);
  } catch (e) {
    console.log(e);
  }
};

const queryApiCalls = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "GetAllCompanyCall",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiCalls: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const queryProducts = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "ListCompanyProducts",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProducts: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const queryProfile = async () => {
  try {
    const { companyAdmin } = companyData.value || {};
    if (!companyAdmin) return;

    const res = await query({
      endpoint: "GetEmployeeProfile",
      payload: {
        input: {
          employeeId: companyAdmin._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProfile: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};
</script>

<style lang="scss" scoped>
@import url(../../styles/www.scss);

[hidden] {
  visibility: hidden;
}

.benefitsContainer {
  background-image: url("./assets/benefitsBanner.png");
  background-color: #11453b;
  background-position: center;
  background-size: cover;
}
.imageCard {
  width: 72px;
}
.hero-section {
  background: linear-gradient(
    180deg,
    #ffe8e6 -67.96%,
    rgba(245, 213, 210, 0) 100%
  );

  h1 {
    span {
      color: #ea4e4b;
    }
  }
  .l-container {
    width: 100%;
  }

  &__img {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
    }
  }
}

.loans-section {
  .heading {
    span {
      color: #ea4e4b;
    }
  }
}

.bills-and-payments-section {
  background-color: #f0f7eb;

  .bills-items-row {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .item {
    padding: 20px;
    width: max(30%, 180px);
    background-color: white;
    box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .item-icon {
      background: #f2f1f1;
      border-radius: 12px;
      width: 60px;
      height: 60px;
      display: grid;
      place-items: center;
      margin-bottom: 14px;
      img {
        width: 25px;
      }
    }

    .item-label {
      font-size: 14px;
    }
  }
}

@screen lg {
  .hero-section {
    overflow: hidden;

    &__side {
      padding: 210px 0;
    }

    &__img {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 58%;
      max-width: none;

      img {
        max-width: none;
        max-height: 100%;
      }
    }
  }

  .bills-and-payments-section {
    .item {
      min-width: 250px;
      width: auto;
      padding: 20px 40px;

      .item-label {
        font-size: 16px;
      }
    }

    .bills-items-row {
      &.-a {
        animation: slideInFromRight 1s cubic-bezier(0, 0.24, 0.59, 1.23);
      }

      &.-b {
        animation: slideInFromLeft 1s cubic-bezier(0, 0.24, 0.59, 1.23);
      }
    }
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
