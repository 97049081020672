<template>
  <!-- Navbar -->
  <nav
    class="no-printable sticky top-0 left-0 w-full z-50 bg-white flex md:flex-row md:flex-nowrap md:justify-start items-center p-2 md:shadow-lg"
  >
    <div
      class="w-full mx-auto items-center flex justify-between flex-nowrap flex-wrap md:px-16 py-1 px-2 md:px-4"
    >
      <!-- Brand -->
      <!-- <img src="@/assets/img/customerLogo.png" class="w-24" alt="" /> -->

      <div
        class="flex gap-2 bg-newLimeGreen rounded-full my-2 py-2 px-5 font-medium cursor-pointer"
        @click="openDocumentationInNewTab"
      >
        <p class="text-primary text-sm">Go to Developers Documentation</p>
        <img src="@/assets/icons/navArrow.svg" alt="" />
      </div>

      <!-- User -->
      <ul class="gap-x-4 list-none items-center flex">
        <user-dropdown />
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script setup>
import UserDropdown from "@/components/Dropdowns/ApiUserDropDown.vue";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { computed, onMounted } from "vue";

import dummyLogo from "@/assets/img/c-logo.png";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { log } = helperFunctions;
const openDocumentationInNewTab=() =>{
    window.open('/developers/documentation', '_blank');
  }
const companyData = computed(() => store.getCompanyDetails);

const getAvatar = (comp) => {
  let name = "",
    color;
  // Get color
  const colors = [
    "#D32F2F",
    "#189F00",
    "#0A244F",
    "#E4572E",
    "#E0A409",
    "#184EA8",
    "#F39C36",
    "#4f46e5",
  ];

  log(companyData.value);

  const randomNumber = Math.floor(Math.random() * 5);
  color = colors[randomNumber + 1];

  comp
    ? (name = `${
        comp &&
        comp.company &&
        comp.company.companyName &&
        comp.company.companyName.charAt(0)
      }${
        comp &&
        comp.company &&
        comp.company.companyName &&
        comp.company.companyName.charAt(0)
      }`)
    : "";

  return {
    color,
    name,
  };
};

async function queryCompany() {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  }
}
const fetchCompanyAdminDetails = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  }

  // log(adminDetails);
};

onMounted(async () => {
  await queryCompany();
  await fetchCompanyAdminDetails();
});
</script>

<style scoped>
.shadow-box {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
}
</style>
