<template>
  <div class="relative rounded-xl border border-outline p-2">
    <div
      @click="showDropdown = !showDropdown"
      class="items-center cursor-pointer flex gap-2 md:gap-2 relative"
    >
      <span
        class="text-sm text-white inline-flex items-center justify-center rounded-full h-6 w-4 sm:w-6 sm:h-6"
      >
        <img
          v-if="activeCompany && activeCompany.company.logo"
          alt="..."
          class="w-full h-full object-cover rounded-full align-middle border-none shadow-lg"
          :src="activeCompany && activeCompany.company.logo"
        />
        <div
          v-else
          class="p-3 h-full w-full flex items-center justify-center bg-primary border-highlight text-base text-white font-medium rounded-full"
        >
          {{ getInitials(activeCompany && activeCompany.company.companyName) }}
        </div>
      </span>
      <div class="flex flex-col text-left">
        <span class="font-normal text-sm text-dark-900 capitalize">{{
          activeCompany && activeCompany.company.companyName
        }}</span>
      </div>
      <div>
        <img src="@/assets/logo/arrow-down.png" class="w-3" alt="" />
      </div>
    </div>
    <div
      v-show="showDropdown"
      class="rounded-xl flex p-1 z-10 flex-col w-64 border-t border-dark-100 bg-white absolute -left-24 md:-left-28 mt-1 -right-4 top-14 py-2 shadow-lg text-sm"
    >
      <div v-if="companyList.length" class="px-6 py-4 flex flex-col gap-4">
        <div
          v-for="company in companyList"
          :key="company._id"
          class="flex justify-between cursor-pointer"
          @click="switchAccount(company)"
        >
          <div class="flex gap-2">
            <span
              class="text-sm text-white inline-flex items-center justify-center rounded-full"
            >
              <img
                v-if="company && company.company && company.company.logo"
                alt="..."
                class="h-6 w-4 sm:w-5 sm:h-5 object-cover rounded-full align-middle border-none shadow-lg"
                :src="company && company.company && company.company.logo"
              />
              <div
                v-else
                class="p-3 uppercase h-6 w-4 sm:w-5 sm:h-5 flex items-center justify-center bg-primary border-highlight text-xs text-white font-medium rounded-full"
              >
                {{ getInitials(company.company.companyName) }}
              </div>
            </span>
            <div class="flex flex-col text-left">
              <span class="font-normal text-sm text-dark-900 capitalize"
                >{{
                  company.company && company.company.companyName != undefined
                    ? company.company.companyName
                    : "Admin"
                }}
              </span>
            </div>
          </div>
          <img
            v-if="selectedId === company._id"
            src="@/assets/logo/check.png"
            class="w-5"
            alt=""
          />
        </div>
      </div>

      <hr v-if="companyList.length" />
      <div v-for="(item, i) in options" :key="i">
        <div
          v-if="item.show"
          @click="optionsFN(item)"
          class="flex items-center gap-2 px-5 py-3 cursor-pointer relative hover:bg-mint rounded-md"
        >
          <span class="w-4">
            <img :src="item.icon" alt="" />
          </span>
          <span>{{ item.name }}</span>

          <div
            v-show="item.others && selected === item.name"
            class="flex z-10 flex-col bg-white absolute -left-44 -ml-1 top-0 py-2 shadow-lg text-sm"
          >
            <div
              v-for="(item, i) in item.others"
              :key="i"
              @click="optionsFN(item)"
              class="flex items-center hover:text-secondary gap-4 px-5 py-3 cursor-pointer relative"
            >
              <span class="w-4">
                <img v-if="selected === item.name" :src="item.active" alt="" />
                <img v-else :src="item.icon" alt="" />
              </span>
              <span :class="selected === item.name ? 'text-secondary' : ''">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-account
    v-if="showAddAccount"
    @close="(showAddAccount = false), (selected = null)"
    :formType="addAccountType"
  />

  <div
    @click="(showDropdown = false), (selected = '')"
    :class="!showDropdown ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>

  <APIDashboardLoader v-if="loading.account" />
</template>

<script setup>
import { logout } from "@/api/api";
import { computed, onMounted, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import AddAccount from "@/components/Settings/Edit/AddAccount.vue";

import profile from "@/assets/icons/user-view.svg";
import activeProfile from "@/assets/icons/active-user-view.svg";

import accountAdd from "@/assets/logo/arrow-uturn-left.png";
import activeAccountAdd from "@/assets/logo/arrow-uturn-left.png";

import plus from "@/assets/icons/plus-dark.svg";

import logoutIcon from "@/assets/logo/arrow-right-on-rectangle.png";
import activeLogout from "@/assets/logo/arrow-right-on-rectangle.png";

const store = useDataStore();

const { query, mutate } = store;
const router = useRouter();
const toast = useToast();

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
  selectedId.value = activeCompany.value && activeCompany.value._id;
}

onMounted(async () => {
  await queryCompany();
});

const selected = ref("");
const addAccountType = ref("");
const showDropdown = ref(false);
const showAddAccount = ref(false);

const options = computed(() => {
  return [
    {
      name: "Add account",
      link: null,
      path: null,
      icon: plus,
      active: plus,
      show: userProfile.value && userProfile.value.membership == "owner",
      others: [
        {
          name: "Business Account",
          link: null,
          path: null,
          icon: profile,
          active: activeProfile,
        },
        {
          name: "Personal Account",
          link: null,
          path: null,
          icon: profile,
          active: activeProfile,
        },
      ],
    },
    {
      name: "Return to Eazipay Business",
      link: "Register",
      path: "/admin/dashboard",
      icon: accountAdd,
      active: activeAccountAdd,
      show: userProfile.value && userProfile.value.membership == "owner",
    },

    {
      name:
        userProfile.value && userProfile.value.membership == "owner"
          ? "Logout of all accounts"
          : "Logout",
      link: null,
      path: "/login",
      icon: logoutIcon,
      active: activeLogout,
      show: true,
    },
  ];
});

const userProfile = computed(() => store.apiProfile);
const companyData = computed(() => store.getCompanyAdmin);

const selectedId = ref(null);

const activeCompany = computed(() => store.getCompanyDetails);

const companyList = computed(() => {
  const arr = store.allCompanyDetails || [];
  return arr.filter((el) => el._id !== activeCompany.value._id);
});

const loading = ref({
  account: false,
  delete: false,
  select: false,
});

const showOptions = ref(null);

const toggleShowOptions = (index) => {
  showOptions.value = showOptions.value === index ? null : index;
};

const switchAccount = async (account) => {
  if (!account.selectedCompany) {
    loading.value.account = true;
    selectedId.value = account._id;
    await selectAccount();
    showDropdown.value = false;
    toggleShowOptions(null);
  } else {
    toast.error("Account already active");
  }
};

const selectAccount = async () => {
  if (activeCompany.value._id !== selectedId.value) {
    loading.value.select = true;
    try {
      const res = await mutate({
        endpoint: "SelectCompany",
        service: "SETTINGS",
        data: { companyId: selectedId.value },
      });

      if (res.success) {
        loading.value.select = false;
        loading.value.account = false;

        await queryCompany();
        await queryCompanyAdmin();

        await queryContributions();
        await queryProducts();
        await queryApiCalls();
        await queryTeams();
        await queryTestKeys();

        // queryEmployees();
        // queryWalletBalance();
        // queryAllowance();
        // queryBonusOrDed();
        // queryBonus();
        // queryDeduction();
        // queryAutomations();

        toast.success("Account switched successfully");

        // window.location.reload();
      }
    } catch (e) {
      loading.value.select = false;
      loading.value.account = false;
      console.log(e);
    }
  } else {
    toast.error("Account already active");
  }
};

//OTHER METHODS

const getInitials = (name) => {
  if (name && name.length) {
    const arr = name.split(" ");
    const first = arr[0] || "";
    const second = arr[1] || "";
    let str = `${first && first[0]}${second && second[0]}`;
    return str;
  } else {
    return "N/A";
  }
};

const optionsFN = (arg) => {
  selected.value = arg.name;
  if (arg.name === "Business Account") {
    addAccountType.value = "business";
    showAddAccount.value = true;
    showDropdown.value = false;
  } else if (arg.name === "Personal Account") {
    addAccountType.value = "personal";
    showAddAccount.value = true;
    showDropdown.value = false;
  } else if (arg.link) {
    router.push(arg.path);
    showDropdown.value = false;
  } else if (arg.name === "Logout of all accounts") {
    logout("logout");
  }
};

//QUERIES METHODS

const queryContributions = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const payload = {
      input: {
        companyId: company._id,
        filterDays: 7,
        from: null,
        productId: null,
        to: null,
      },
    };

    const res = await query({
      endpoint: "GetCompanyContributions",
      payload: payload,
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiContribution: res.data,
      });
    }

    console.log(res.data);
  } catch (e) {
    console.log(e);
  }
};

const queryApiCalls = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "GetAllCompanyCall",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiCalls: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const queryProducts = async () => {
  try {
    const { company } = companyData.value;
    if (!company) return;

    const res = await query({
      endpoint: "ListCompanyProducts",
      payload: {
        input: {
          companyId: company._id,
        },
      },
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiProducts: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const queryTeams = async () => {
  try {
    const res = await query({
      endpoint: "ListTeamMembers",
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiTeams: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const queryTestKeys = async () => {
  try {
    const res = await query({
      endpoint: "ViewCompanyTestKeys",
      service: "API",
    });

    if (res.success) {
      store.$patch({
        apiKeys: res.data,
      });
    }

    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

const queryAutomations = async () => {
  try {
    await query({
      endpoint: "ListPayrollAutomations",
      service: "PAYROLL",
      storeKey: "payrollAutomations",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "deductions",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "bonuses",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryBonusOrDed = async () => {
  try {
    query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
};
const queryWalletBalance = async () => {
  try {
    query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: "",
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });
  } catch (err) {
    console.log(err);
  }
};

const queryAllowance = () => {
  query({
    endpoint: "ListAllowance",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: null,
      },
    },
    service: "PAYROLL",
    storeKey: "allowance",
  });
};

const queryEmployees = async () => {
  query({
    endpoint: "ListEmployees",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
        sort: { firstName: "ASC" },
      },
      filter: [],
    },
    service: "EMP",
    storeKey: "listEmployees",
  });
};
</script>
