<template>
  <!-- class="" -->
  <nav
    class="no-printable no-scrollbar shadow-md sticky relative top-0 left-0 bg-white flex flex-wrap items-center justify-between z-40 py-0 px-0 md:shadow-xl md:w-60 md:block md:fixed md:left-0 md:top-0 md:bottom-0 md:flex-row md:flex-nowrap lg:overflow-y-auto"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full sticky md:p-0 md:mx-auto"
    >
      <router-link
        class="md:block hidden text-left md:pb-0 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm capitalize font-bold p-0 px-0 mb-4"
        to="/"
      >
        <span class="w-full">
          <img :src="logo" alt="Eazipay" />
        </span>
      </router-link>
      <!-- User -->
      <div v-if="!otherRoutes" class="w-full">
        <div
          class="md:hidden flex flex-wrap w-full"
          v-for="link in links"
          :key="link.main"
        >
          <div
            v-if="$route.path.includes(link.pathKey)"
            class="w-full p-4 flex gap-2 justify-between items-center overflow-x-auto no-scrollbar py-6"
          >
            <div
              v-for="sub in link.subs"
              :key="sub.name"
              class="inline-block relativ"
            >
              <router-link
                @click="moveCenter($event)"
                :to="sub.path"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="text-sm whitespace-nowrap px-2"
                  :class="[
                    $route.path.includes(sub.path)
                      ? 'text-primary font-medium border-b-2 border-primary '
                      : 'text-gray hover:text-primary',
                    sub.name.toLowerCase() === 'edit salary' &&
                    $route.path.includes('bulk')
                      ? 'text-primary font-medium border-b-2 border-primary '
                      : 'text-gray hover:text-primary',
                  ]"
                  >{{ sub.name }}
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none px-6 py-3 md:p-0 shadow absolute inset-0 z-50 overflow-y-auto overflow-x-hidden h-screen items-center flex-1"
        :class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 my-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex items-center justify-between">
            <button
              type="button"
              class="cursor-pointer"
              v-on:click="toggleCollapseShow('hidden')"
            >
              <img src="@/assets/icons/close.svg" alt="" />
            </button>
            <button
              type="button"
              class="cursor-pointer flex items-center gap-2"
              v-on:click="logout"
            >
              <img src="@/assets/icons/active-logout.svg" alt="" />
              <span class="text-secondary text-sm font-medium">Logout</span>
            </button>
          </div>
          <div class="w-full flex mt-4 p-4 easiGradient">
            <img
              class="w-28 mx-auto"
              src="@/assets/icons/easipay-white.svg"
              alt=""
            />
          </div>
        </div>

        <!-- Heading -->
        <!-- <h6
            class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          >
            Admin Layout Pages
          </h6> -->
        <!-- Navigation -->

        <ul class="w-full flex flex-col list-none">
          <li class="flex-1 grow px-0">
            <router-link
              to="/developers/dashboard/overview"
              v-slot="{ href, navigate, isActive }"
              :class="isActive ? 'active-bg' : undefined"
            >
              <a
                :href="href"
                @click="
                  () => {
                    toggleCollapseShow('hidden');
                    navigate;
                  }
                "
                class="text-sm text-left capitalize py-3 px-4 w-full h-full font-medium flex items-center gap-2"
                :class="[
                  isActive
                    ? 'text-primary  border-l-2 border-5 active-bg border-primary'
                    : 'text-grey hover:text-primary ',
                ]"
              >
                <img
                  v-if="!isActive"
                  class="w-4 contain"
                  src="@/assets/icons/map.svg"
                  alt=""
                />
                <img
                  v-else
                  class="w-4 contain"
                  src="@/assets/icons/map.svg"
                  alt=""
                />
                <span> Overview</span>
              </a>
            </router-link>
          </li>
          <!-- Divider -->
          <hr class="mt-1 text-dividerColor md:min-w-full" />
        </ul>
        <div
          class="cursor-pointer w-full flex flex-col items-center justify-center accordion acc-single-open z-1"
        >
          <div
            v-for="(link, index) in links"
            :key="link.main"
            class="acc-container z-1 w-full grow"
            :class="
              $route.path.includes(link.pathKey)
                ? 'text-primary  border-l-2 border-5 active-bg border-primary'
                : undefined
            "
          >
            <div
              @click="
                !link.subs && $router.push({ path: link.path }),
                  !link.subs && toggleCollapseShow('hidden')
              "
              class="flex justify-between items-center py-4 text-sm text-left capitalize font-bold mx-4 acc-title"
              :class="[
                $route.path.includes(link.pathKey)
                  ? 'text-primary '
                  : 'text-grey hover:text-primary',
              ]"
            >
              <div
                v-if="link.icon"
                class="pointer-events-none -z-1 mr-1 -mb-1 flex items-center gap-2"
              >
                <i :class="link.icon" class="mr-1"></i>
                <span class="pointer-events-none justify-self-start text-left">
                  {{ link.main }}</span
                >
              </div>
              <div
                class="pointer-events-none mr-1 -z-1 -mb-1 flex items-center gap-2"
                v-else
              >
                <img
                  v-if="!$route.path.includes(link.pathKey)"
                  class="w-4 contain"
                  :src="link.importIcon"
                  alt=""
                />
                <img
                  v-else
                  class="w-4 contain"
                  :src="link.importIconActive"
                  alt=""
                />
                <span
                  class="pointer-events-none -z-1 justify-self-start text-left"
                >
                  {{ link.main }}</span
                >
              </div>
              <span v-if="link.subs" class="pointer-events-none -z-1">
                <i
                  :class="
                    link.active ? 'fas fa-angle-down' : 'fas fa-angle-right'
                  "
                  class="inline-flex justify-end self-end text-right cursor-pointer text-sm text-grey"
                />
              </span>
            </div>
            <div v-for="sub in link.subs" :key="sub.name" class="">
              <div
                v-if="isAllowedOnPersonalAccount(sub)"
                class="text-left px-4 acc-content -z-1"
              >
                <router-link :to="sub.path" v-slot="{ href, navigate }">
                  <a
                    :href="href"
                    @click="navigate, toggleCollapseShow('hidden')"
                    class="text-left mx-4 text-sm font-medium -z-1"
                    :class="[
                      $route.path.includes(sub.path)
                        ? 'text-secondary '
                        : 'text-grey hover:text-primary',
                    ]"
                  >
                    <img
                      :src="
                        $route.path.includes(sub.path)
                          ? activeBulletPoint
                          : inactiveBulletPoint
                      "
                      class="inline-block mr-2 -mt-3px text-secondary"
                      alt=""
                    />
                    {{ sub.name }}
                  </a>
                </router-link>
              </div>
            </div>
            <hr
              v-show="index !== links.length - 1"
              class="my-0 text-dividerColor md:min-w-full"
            />
            <!-- acc-content -->
          </div>
          <!-- acc-container -->
        </div>
      </div>

      <!-- Button Navigation -->
      <div
        class="md:hidden w-full z-50 fixed bottom-0 left-0 px-2 bg-white flex justify-around"
        style="box-shadow: 0 4px 16px 2px rgba(0, 0, 0, 0.2)"
      >
        <div
          v-for="link in bottomLinks"
          :key="link.name"
          @click="$router.push(link.path)"
          class="flex flex-col items-center gap-1 p-4 cursor-pointer"
          :class="
            $route.path.includes(link.pathKey)
              ? 'text-primary   active-bg border-primary'
              : undefined
          "
        >
          <img
            class="w-6"
            :src="$route.path.includes(link.pathKey) ? link.active : link.icon"
            alt=""
          />
          <span
            :class="
              $route.path.includes(link.pathKey)
                ? 'text-primary'
                : 'text-dark-900'
            "
            class="text-xs font-medium"
            >{{ link.name }}</span
          >
        </div>
        <div
          @click="
            collapseShow.includes('hidden')
              ? toggleCollapseShow('bg-white')
              : toggleCollapseShow('hidden')
          "
          :class="otherRoutes ? 'text-primary' : 'text-dark-900'"
          class="flex flex-col items-center justify-center gap-1 p-4 cursor-pointer py-3 px-6 md:py-0 md:px-0"
        >
          <img :src="otherRoutes ? newMore : more" alt="Eazipay" />

          <span class="text-xs font-medium">More</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logout } from "@/api/api";

// import logo from "@/assets/img/eaziLogo.png";
import logo from "@/assets/img/apiBanner.png";
import home from "@/assets/icons/home.svg";
import homeActive from "@/assets/icons/newHomeActive.svg";

import newMore from "@/assets/icons/newMore.svg";
import more from "@/assets/icons/moreInactive.svg";
import overViewIcon from "@/assets/icons/map.svg";

import payroll from "@/assets/icons/payroll.svg";
import payrollActive from "@/assets/icons/newPayrollActive.svg";

import employee from "@/assets/icons/employee.svg";
import employeeActive from "@/assets/icons/newEmployeeActive.svg";

import apiProfileIcon from "../../assets/icons/apiProfile.svg";
import apiTeamIcon from "../../assets/icons/apiTeam.svg";
import apiSettingIcon from "../../assets/icons/apiSettings.svg";

import apiKeyIcon from "@/assets/icons/apiApi.svg";

import activeBulletPoint from "@/assets/icons/activeBulletPoint.svg";
import inactiveBulletPoint from "@/assets/icons/newBulletPoint.svg";

import userView from "@/assets/icons/user-view.svg";
import activeUserView from "@/assets/icons/active-user-view.svg";

import userEdit from "@/assets/icons/user-edit.svg";
import activeUserEdit from "@/assets/icons/active-user-edit.svg";

import { mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
const store = useDataStore();

export default {
  data() {
    return {
      newMore,
      isActive: false,
      more,
      logout,
      logo: logo,
      collapseShow: "hidden",
      collapse: false,
      activeBulletPoint,
      inactiveBulletPoint,
      links: [
        {
          active: false,
          main: "Api Keys",
          // icon: "fas fa-user",
          importIcon: apiKeyIcon,
          importIconActive: apiKeyIcon,
          pathKey: "api-keys",
          subs: [
            {
              name: "Test Keys",
              link: "APITestKeys",
              path: "/developers/dashboard/api-keys/test-keys",
              icon: apiKeyIcon,
              active: apiKeyIcon,
            },
            {
              name: "Product",
              link: "APIProduct",
              path: "/developers/dashboard/api-keys/product",
              icon: userView,
              active: activeUserView,
            },
          ],
        },
        {
          active: false,
          main: "Profile",
          icon: null,
          importIcon: apiProfileIcon,
          importIconActive: apiProfileIcon,
          pathKey: "profile",
          path: "/developers/dashboard/profile",
          subs: null,
        },
        {
          active: false,
          main: "Team",
          icon: null,
          importIcon: apiTeamIcon,
          importIconActive: apiTeamIcon,
          pathKey: "team",
          path: "/developers/dashboard/team",
          subs: null,
        },
        {
          active: false,
          main: "Settings",
          // icon: "fas fa-user",
          importIcon: apiSettingIcon,
          importIconActive: apiSettingIcon,
          pathKey: "settings",
          subs: [
            {
              name: "Password",
              link: "APIPassword",
              path: "/developers/dashboard/settings/password",
              icon: apiKeyIcon,
              active: apiKeyIcon,
            },
            {
              name: "Support",
              link: "APISupport",
              path: "/developers/dashboard/settings/support",
              icon: userView,
              active: activeUserView,
            },
          ],
        },
      ],

      bottomLinks: [
        {
          name: "OverView",
          icon: overViewIcon,
          active: overViewIcon,
          pathKey: "overview",
          path: "/developers/dashboard/overview",
        },
        {
          name: "Profile",
          icon: apiProfileIcon,
          active: apiProfileIcon,
          pathKey: "profile",
          path: "/developers/dashboard/profile",
        },

        {
          name: "Team",
          icon: apiTeamIcon,
          active: apiTeamIcon,
          pathKey: "team",
          path: "/developers/dashboard/team",
        },
      ],
    };
  },
  computed: {
    ...mapWritableState(useDataStore, ["getCompanyDetails"]),
    companyData: (store) => store.getCompanyDetails,
    otherRoutes() {
      if (
        this.$route.path.includes("settings") ||
        this.$route.path.includes("api-keys")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    isAllowedOnPersonalAccount(sub) {
      const isPersonal =
        this.companyData && this.companyData.registrationType !== "COMPANY";

      if (isPersonal && sub.link === "BusinessProfile") {
        return false;
      } else {
        return true;
      }
    },
    toggleCollapseShow: function (classes, i) {
      console.log(classes);
      if (i !== undefined) {
        this.links[i].active = !this.links[i].active;
      }
      this.collapseShow = classes;
    },

    moveCenter: function (e) {
      const element = e.target;
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },
};
</script>

<style scoped>
.accordion {
  margin: 0 0 10px 0;
}

.acc-container {
  /* margin: 0 0 10px; */
  max-width: 100%;
  overflow: hidden;
}

.acc-title {
  max-width: 100%;
  z-index: 999;
  /* box-shadow: #4e4e4e; */
  /* background: rgba(242, 247, 253, 0.5); */
  /* color: #4e4e4e; */
  /* border-radius: 4px; */
  /* padding: 14px 20px; */
  /* font-size: 16px; */
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease-in-out;
}

/* .acc-active .acc-title,
  .acc-title:hover {
    background: #183059;
    color: white;
    cursor: pointer;
  } */

.acc-title:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3Cline y1='6' x1='12.5' y2='19' x2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Plus sign*/
  position: absolute;
  right: 20px;
  top: 50%;
  /* margin-top: -12.5px; */
  width: 25px;
  height: 25px;
  display: none;
}

.acc-active .acc-title:after {
  display: none;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Minus sign*/
}

.acc-content {
  background: #f8f8f8;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

.acc-active .acc-content {
  /* font-size: 16px; */
  padding: 10px 0;
  max-height: 500px;
  overflow-y: auto;
  opacity: 1;
}

.active-bg {
  background: rgba(240, 247, 235, 0.5);
}
</style>

<!-- md:left-0 no-printable md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:shadow-xl shadow-md sticky top-0 left-0 bg-white flex flex-wrap items-center justify-between relative md:w-60 z-50 py-0 px-0 -->
